<template>
    <div class="about-us">
        <div class="os-item">
            <video id="osvideo" class="video" controls="" autoplay="autoplay">
                <source src="https://ve.media.tumblr.com/tumblr_o0h1c72s4O1v25v5a_480.mp4" type="video/mp4">
            </video>
            <h3 class="os-title">中醫在綫-現代化中醫診所管理系統</h3>
            <p class="os-desc">符合中醫藥管理委員會最新要求</p>
            <div class="os-operate">
                <router-link to="/os/doctor_signup" class="btn-m-account">免費申請診所管理系統賬號</router-link>
                <a href="https://os.ectcm.com/OnlineBook/" class="btn-m-more" target="_blank">了解更多</a>
            </div>
        </div>
        <div class="app-item">
            <img src="../../../assets/imgs/mobile/img_dayilogo@2x.png" alt="大醫App" class="icon-app">
            <h3 class="app-name">大醫APP</h3>
            <span class="app-from">由中醫在綫開發推出</span>
            <p class="app-desc">大醫APP是一款專注于智慧健康、疾病防禦、健康管理為理念，致力於提供“互動、便捷、全面”的健康管家。</p>
        </div>
        <div class="app-modules-item">
            <div class="app-module app-module-1">
                <i class="icon"></i>
                <span class="app-module-title">【診症預約】</span>
                <div class="app-module-desc">便捷的線上選擇搜索醫師進行預約掛號</div>
            </div>
            <div class="app-module app-module-2">
                <i class="icon"></i>
                <span class="app-module-title">【檢查記錄】</span>
                <div class="app-module-desc">添加/查詢健康記錄、查詢就診記錄、服務卡和療程消費情況</div>
            </div>
            <div class="app-module app-module-3">
                <i class="icon"></i>
                <span class="app-module-title">【用藥提醒】</span>
                <div class="app-module-desc">設定吃藥時間，到點會有鈴聲提醒，忘吃藥從此一去不返</div>
            </div>
            <div class="app-module app-module-4">
                <i class="icon"></i>
                <span class="app-module-title">【月經記錄】</span>
                <div class="app-module-desc">針對女性用戶的月經功能，記錄月經預測往後月經日期，排卵期等；根據月經記錄分析相關月經病及異常，給與相應健康建議</div>
            </div>
            <!-- <div class="pet-module">
                <i class="icon"></i>
                <span class="pet-module-title">貼心寵物</span>
                <div class="pet-module-desc">可以與你互動的健康小助手</div>
            </div> -->
        </div>
        <div class="app-download">
            <div class="app-qrcode">
                <img src="../../../assets/imgs/qr_code.png" alt="">
                <span class="qrcode-title">掃碼下載大醫App</span>
            </div>
        </div>
        <div class="clinics">
            <div class="clinic-head">使用中醫在綫的診所</div> 
            <div class="clinic-list">
                <div class="clinic-list-warpper">
                    <a href="javascript:;" class="clinic-item" v-for="(clinic, index) in clinicList" :key="index">
                        <img class="clinic-icon" :src="clinic.logo" alt="">
                        <div class="col">
                            <router-link :to="`/hospital/detail/${clinic.clinicid}`" class="clinic-name" v-text="clinic.clinicname"></router-link>
                            <router-link :to="`/doctor/list?AreaLv1=${clinic.arealevel1id}&AreaLv2=${clinic.arealevel2id}`" class="clinic-address" v-text="`${clinic.arealevel1name}>${clinic.arealevel2name}`"></router-link>
                        </div>
                    </a>
                </div>
                <router-link to="/hospital/list" class="btn-more-clinics">查看全部</router-link>
            </div>
        </div>
        <div class="foot-item">
            <div class="links-header">友情鏈接：</div>
            <div class="fr-link-list">
               <a class="fr-link-item" v-for="item in this.constants.friendlyLinks" :href="item.url" target="_blank" v-text="item.title"></a>
            </div>
            <div class="about-us-header">關於系統：</div>
            <div class="about-us-list">
                <router-link class="about-us-item" to="/home/about">關於我們</router-link>
                <router-link class="about-us-item feedback" to="/home/feedback">用戶反饋</router-link>
                <router-link class="about-us-item" to="/home/disclaimer">使用條款及免責聲明</router-link>
                <router-link class="about-us-item" to="/home/privacy">私隱政策</router-link>
                <router-link class="about-us-item" to="/home/contact">聯絡我們</router-link>
            </div>
            <div class="safe-icos">
                <a href="javascript:;" data-url="https://seal.godaddy.com/verifySeal?sealID=H1ahlwwmjgaLEmPXgaSipSp5JvRqE5ESwDOgW4qjVZ4kYfENswwF51bdQiuz" class="safe-icon-link"><i class="icon-safe icon1"></i></a>
                <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon2"></i></a>
                <a href="https://www.mcafeesecure.com/verify?host=ectcm.com&popup=1" target="_blank" class="safe-icon-link"><i class="icon-safe icon3"></i></a>
                <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon4"></i></a>
            </div>
            <div class="foot-b">
                Copyright ©{{new Date().getFullYear()}} 版權所有<br/>深圳本草通网络有限公司<br/>Shenzhen Bencaotong Network Co., Ltd.
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        data: function(){
            return {
                clinicList: [],
            }
        },
        created: function(){
            this.$store.dispatch('getHomeClinics', {pageNo: 1, pageSize: 5})
            .then((_rs) => {
                this.clinicList = (_rs && _rs.list) || []
            })
        }
    }
</script>

<style lang="scss" scoped>
    .about-us {
        display: flex;
        flex-direction: column;
        margin: 0px 24px;
    }
    .os-item {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
    }

    .os-item > .video {
        height: 376px;
        width: 100%;
        margin: 30px auto 5px auto;
    }

    .os-item > .os-title {
        font-size: 32px;
        color: #231F20;
        line-height: 31px;
        font-weight: bold;
        margin-top: 50px;
    }

    .os-item > .os-desc {
        font-size: 26px;
        color: #636363;
        line-height: 25px;
        font-weight: 400;
        margin-top: 17px;
    }

    .os-item > .os-operate {
        display: flex;
        flex-direction: row;
        margin: 40px 0px 49px 0px;
    }

    .os-item > .os-operate > .btn-m-account {
        height: 56px;
        line-height: 56px;
        text-align: center;
        padding-left: 51px;
        padding-right: 12px;
        position: relative;
        border-radius: 8px;
        background-color: #FFDEBD;
        font-size: 26px;
        color: #72240B;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .os-item > .os-operate > .btn-m-account::before {
        width: 26px;
        height: 24px;
        content: '';
        position: absolute;
        left: 17px;
        top: 16px;
        background-image: url(../../../assets/imgs/mobile/icon_zhensuo@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .os-item > .os-operate > .btn-m-more {
        height: 56px;
        line-height: 56px;
        text-align: center;
        padding-left: 66px;
        padding-right: 12px;
        position: relative;
        border-radius: 8px;
        background-color: #7AE1ED;
        font-size: 26px;
        color: #043A3D;
        margin-left: 48px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .os-item > .os-operate > .btn-m-more::before {
        width: 32px;
        height: 26px;
        content: '';
        position: absolute;
        left: 20px;
        top: 16px;
        background-image: url(../../../assets/imgs/mobile/icon_liaojie@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .os-item > .os-operate > .btn-m-account:active,
    .os-item > .os-operate > .btn-m-more:active {
        opacity: 9;
    }


    .app-item {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #FFF4EF;
        margin-top: 16px;
    }

    .app-item > img {
        width: 72px;
        height: 72px;
        position: absolute;
        left: 16px;
        top: 20px;
    }

    .app-item > .app-name {
        color: #231F20;
        font-size: 32px;
        line-height: 31px;
        margin-left: 104px;
        margin-top: 21px;
        font-weight: bold;
    }

    .app-item > .app-from {
        color: #5F5557;
        font-size: 24px;
        line-height: 23px;
        font-weight: 400;
        margin-left: 103px;
        margin-top: 13px;
    }

    .app-item > .app-desc {
        margin: 33px 18px 32px 17px;
        line-height: 30px;
        color: #5F5557;
        font-size: 24px;
        font-weight: 400;
    }

    .app-modules-item {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 10px 18px;
    }

    .app-modules-item > .app-module {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 94px;
        margin-top: 37px;
    }

    .app-modules-item > .app-module > .icon {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 78px;
        height: 78px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .app-modules-item > .app-module.app-module-1 > .icon {
        background-image: url(../../../assets/imgs/mobile/img_yuyue@2x.png);
    }

    .app-modules-item > .app-module.app-module-2 > .icon {
        background-image: url(../../../assets/imgs/mobile/img_jiancha@2x.png);
    }

    .app-modules-item > .app-module.app-module-3 > .icon {
        background-image: url(../../../assets/imgs/mobile/img_yongyao@2x.png);
    }

    .app-modules-item > .app-module.app-module-4 > .icon {
        background-image: url(../../../assets/imgs/mobile/img_yuejing@2x.png);
    }

    .app-modules-item > .app-module > .app-module-title {
        color: #231F20;
        font-size: 32px;
        line-height: 31px;
        font-weight: bold;
        margin-left: -15px;
    }

    .app-modules-item > .app-module > .app-module-desc {
        color: #969696;
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        margin-top: 22px;
    }

    .app-modules-item > .pet-module {
        display: flex;
        flex-direction: column;
        background-color: #FFDDCD;
        border-radius: 8px;
        position: relative;
        padding-left: 169px;
        padding-bottom: 24px;
        margin-top: 47px;
    }

    .app-modules-item > .pet-module > .icon {
        position: absolute;
        left: 29px;
        top: 19px;
        width: 99px;
        height: 87px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/mobile/img_chongwu@2x.png);
    }

    .app-modules-item > .pet-module > .pet-module-title {
        color: #BE3D00;
        font-size: 32px;
        line-height: 31px;
        font-weight: bold;
        margin-top: 23px;
    }

    .app-modules-item > .pet-module > .pet-module-desc {
        color: #5F5557;
        font-size: 24px;
        line-height: 23px;
        font-weight: 400;
        margin-top: 23px;
    }

    .app-download {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 40px 18px;
    }

    .app-download > .app-qrcode {
        display: flex;
        flex-direction: column;
        flex: none;
        align-items: center;
    }

    .app-download > .app-qrcode > img {
        display: inline-block;
        width: 204px;
        height: 204px;
    }

    .app-download > .app-qrcode > .qrcode-title {
        font-size: 30px;
        color: #231F20;
        white-space: nowrap;
        margin-left: 36px;
        line-height: 36px;
    }

    .app-download > .link-app {
        position: absolute;
        left: 365px;
        top: 48px;
        display: flex;
        flex-direction: row;
        line-height: unset;
        padding: 0px;
        margin: 0px;
    }

    .app-download > .link-app > .icon {
        display: inline-block;
        width: 19px;
        height: 21px;

    }
    .app-download > .link-app > a {
        color: #043A3D;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
    }

    .app-download > .link-app.android {
        top: 113px;
    }

    .clinics {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
    }

    .clinics .clinic-head {
        height: 80px;
        line-height: 80px;
        margin-bottom: 1px;
        padding-left: 60px;
        position: relative;
        background-color: #fff;
        color: #231F20;
        font-size: 32px;
        font-weight: 400;
    }

    .clinics .clinic-head::before {
        content: '';
        position: absolute;
        left: 18px;
        top: 27px;
        width: 29px;
        height: 27px;
        background-image: url(../../../assets/imgs/mobile/img_zs@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .clinics .clinic-list {
        background-color: #fff;
        padding: 24px 24px;
        display: flex;
        flex-direction: column;
    }

    .clinics .clinic-list .clinic-list-warpper {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 78px 78px 78px;
        row-gap: 30px;
        column-gap: 20px;
    }

    .clinics .clinic-list .clinic-item {
        display: inline-block;
        height: 78px;
        display: flex;
        flex-direction: row;
    }

    .clinics .clinic-list .clinic-item .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .clinics .clinic-list .clinic-item .clinic-icon {
        width: 78px;
        height: 78px;
        vertical-align: top;
        position: relative;
        margin-right: 10px;
    }

    .clinics .clinic-list .clinic-item .clinic-icon::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 78px;
        height: 78px;
        background-image: url(../../../assets/imgs/pc/img_moren@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #fff;
    }

    .clinics .clinic-list .clinic-item .clinic-name {
        color: #231F20;
        font-weight: bold;
        font-size: 32px;
        line-height: 31px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .clinics .clinic-list .clinic-item .clinic-address {
        color: #969696;
        font-weight: 400;
        font-size: 24px;
        line-height: 23px;
        left: 90px;
        top: 48px;
    }

    .clinics .clinic-list .btn-more-clinics {
        display: block;
        width: 279px;
        height: 62px;
        line-height: 62px;
        text-align: center;
        color: #fff;
        background-color: #36C4D0;
        border-radius: 10px;
        margin: 53px auto 38px auto;
        font-size: 32px;
        font-weight: bold;
        cursor: pointer;
    }

    .foot-item {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        margin-top: 16px;
        padding: 40px 0 40px 16px;
    }

    .foot-item .about-us-header,
    .foot-item .links-header {
        font-weight: 400;
        color: #969696;
        font-size: 24px;
        line-height: 23px;
    }

    .foot-item .about-us-header {
        margin-top: 16px;
    }

    .foot-item .fr-link-list {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 192px 237px 255px;
        grid-template-rows: 56px 56px 56px;
        grid-auto-flow: row;
    }

    .foot-item .about-us-list {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 237px 195px 255px;
        grid-template-rows: 56px 56px;
        grid-auto-flow: row;
    }

    .foot-item .about-us-list .about-us-item,
    .foot-item .fr-link-list .fr-link-item {
        display: inline-block;
        color: #636363;
        font-size: 24px;
        line-height: 26px;
        font-weight: bold;
        text-decoration: underline;
    }

    .foot-item .safe-icos {
        height: 50px;
        display: flex;
        flex-direction: row;
        margin-top: 25px;
    }

    .foot-item .safe-icos .safe-icon-link {
        display: flex;
        flex-direction: row;
        width: 206px;
        height: 50px;
        margin-left: 19px;
    }

    .foot-item .safe-icos .safe-icon-link:nth-child(1) {
        margin-left: 0px;
    }

    .foot-item .safe-icos .icon-safe {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .foot-item .safe-icos .icon-safe.icon1 {
        background-image: url(../../../assets/imgs/mobile/img_secured@2x.png);
    }

    .foot-item .safe-icos .icon-safe.icon2 {
        width: 129px;
        background-image: url(../../../assets/imgs/mobile/img_peiyu@2x.png);
    }

    .foot-item .safe-icos .icon-safe.icon3 {
        width: 147px;
        background-image: url(../../../assets/imgs/mobile/img_mcafee@2x.png);
    }

    .foot-item .safe-icos .icon-safe.icon4 {
        width: 135px;
        background-image: url(../../../assets/imgs/mobile/img_sll@2x.png);
    }

    .foot-item .foot-b {
        width: 451px;
        line-height: 37px;
        color: #636363;
        font-size: 24px;
        margin: 64px auto 39px auto;
        text-align: center;
    }
</style>
 